<template>
  <v-app-bar absolute app dense class="toolbar" color="white" elevation="2" flat>
    <div class="d-flex align-center">
      <v-img
        alt="HutonNeko Logo"
        class="shrink mr-2"
        contain
        src="../assets/logo.png"
        transition="scale-transition"
        width="40"
      />
    </div>
  </v-app-bar>
</template>
<script>
export default {
  components: {}
};
</script>
<style scoped>
.toolbar {
  z-index: 10;
}
</style>
