<template>
  <v-app>
    <Header />
    <v-content class="grey lighten-4">
      <div class="mx-auto white main-view">
        <router-view v-on:send="doSend" v-bind:state="store.state" />
      </div>
    </v-content>
    <v-divider></v-divider>
    <Footer />
  </v-app>
</template>

<script>
import Header from "./components/header";
import Footer from "./components/footer";

export default {
  name: "App",

  components: {
    Header,
    Footer
  },

  data: () => ({
    store: {
      debug: false,
      state: {
        input_form: null
      },
      set(value) {
        if (this.debug) console.log("setMesseageAction triggered with", value);
        this.state.input_form = value;
        localStorage.input_form = value;
      }
    }
  }),
  methods: {
    doSend: function(value) {
      this.store.set(value);
    }
  },
  created() {
    if (localStorage.input_form) {
      this.store.set(localStorage.input_form);
    }
  }
};
</script>

<style scoped>
.main-view {
  max-width: 800px;
}
</style>
