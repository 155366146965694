<template>
  <v-footer dark padless>
    <v-card class="flex" flat tile>
      <v-card-title class="white">
        <v-spacer></v-spacer>

        <v-btn v-for="icon in icons" :key="icon.name" class="mx-4" dark icon :href="icon.href">
          <v-icon size="24px" color="blue">{{ icon.name }}</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="grey darken-3 py-2 white--text text-center">
        {{ new Date().getFullYear() }} —
        <strong>ふとんねこ</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    icons: [{ name: "fab fa-twitter", href: "https://twitter.com/TwitterJP" }]
  })
};
</script>